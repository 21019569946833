<template>
    <header>
        <div class="header_in">
            <div class="header_logo">
                <div class="logo">
                    <img src="@/assets/images/sparta_logo.png" />
                </div>
                <div class="header_date">
                    <p id="student-time-result" v-html="date_time_str"></p>
                </div>
            </div>
            <TopHearAlarmBar v-if="p_show_alarmbar" />
            <div v-if="is_show_admin_header" id="top_header_admin">
                <!-- <button v-if="is_company_admin"
                    @click="
                        () => {
                            this.$router.push('/selectcenter');
                        }
                    "
                >
                    센터 선택
                </button> -->
                <button
                    @click="
                        () => {
                            this.$router.push('/front');
                        }
                    "
                >
                    프론트 모니터
                </button>
                <button
                    @click="
                        () => {
                            this.$router.push('/manager');
                        }
                    "
                >
                    관리 모니터
                </button>
                <button
                    @click="
                        () => {
                            this.$router.push('/consulting');
                        }
                    "
                >
                    상담 모니터
                </button>
            </div>
            <div class="header_acount">
                <div class="acount_welcome">
                    <p>
                        <span> {{ member_info.name }}({{ member_info.id }}) </span>
                        님 <br />
                        반갑습니다.
                    </p>
                </div>
                <div class="header_acount_button">
                    <div>
                        <a @click="logout()"> 로그아웃 </a>
                        <div v-if="user_info.role == role_student || logout_guide" class="moving_notify" @click="this.logout_guide = false">
                            {{ logout_guide_string }}
                            <img src="@/assets/images/triangle.png" />
                        </div>
                    </div>
                    <div v-if="user_info.role != role_student">
                        <await-button @click="on_teacher_action_pin()">{{ this.user_info?.checkin_time ? "퇴근" : "출근" }}</await-button>
                    </div>
                    <div v-if="account_buttons_disable">
                        <a @click="move_account_page()"> 개인정보 </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import TopHearAlarmBar from "./TopHeaderAlarmBar.vue";
import jwtService from "@/services/jwt.service";
import { CommonUtils, ROLE } from "@/js/util/common";
import { num_format, date_format } from "@/js/util/math";
import ApiService from "@/services/api.service";
import { SCHEDULE_API } from '@/services/exapi.service';

const LOGOUT_TIME = 60 * 2;

export default {
    name: "TopHeader",
    props: {
        show_alarmbar: Boolean
    },

    data() {
        return {
            date_time_str: "",
            p_show_alarmbar: false,
            logout_guide: true,
            account_buttons_disable: true,
            user_info: {},
            role_student: ROLE.STUDENT,
            logout_guide_string: "시스템 사용 완료 후 반드시 로그아웃 하세요",
            timeout: LOGOUT_TIME,
            is_show_admin_header: false,
            is_company_admin : false,
            member_info: { name: "", id: "" }
        };
    },

    computed: {
        user_id: function () {
            return jwtService.getUserInfo()?.userid;
        }
    },
    components: {
        TopHearAlarmBar
    },

    created() {
        this.$EventManager.AddListener("update_status", this, this.start_logout_tick)
        this.$EventManager.AddListener("update_status", this, this.show_account_buttons)
        this.$EventManager.AddListener("set_logout_tick",this,this.set_logout_tick);
        this.show_account_buttons()
        this.d_clock();
        setInterval(this.d_clock, 1000);
        this.p_show_alarmbar = this.$props.show_alarmbar;

        this.user_info = jwtService.getUserInfo();
        var user_info = this.user_info;

        if(user_info?.is_admin)
        // if(user_info?.role == ROLE.COMPANYADMIN)
            this.is_company_admin = true;

        if (this.is_company_admin && this.$route.path.startsWith("/admin"))
        // if ((user_info.role == ROLE.ADMIN || this.is_company_admin) && this.$route.path.startsWith("/admin"))
        {
            this.is_show_admin_header = true;
        }

        var current_page_role = CommonUtils.get_current_page_role(this, user_info.role)

        try {
            const userinfo = {
                center: user_info.center_no,
                role: current_page_role,
                id: user_info.userid,
                key: jwtService.getAccessToken()
            };
            this.$WSSocket.Connect(userinfo);
        } catch (e) {
            console.error(e);
        }

        this.get_member_info();
    },

    unmounted() {
        this.$EventManager.RemoveListener("update_status", this.start_logout_tick);
        this.$EventManager.RemoveListener("update_status", this.show_account_buttons);
        this.$EventManager.RemoveListener("set_logout_tick",this,this.set_logout_tick);
        document.onkeydown = document.onclick = null;
    },

    mounted() {
        this.start_logout_tick();
    },

    methods: {
        async get_member_info(id) {
            this.member_info = (await ApiService.call(ApiService.GET, "/my/info")).data;
            if(!this.user_info?.checkin_time && this.user_info?.checkin_time != false){
                this.on_teacher_action_pin();
            }
        },
        start_logout_tick() {
            if (this.user_info.role == this.role_student) {
                // update_stay 이벤트 헨들링될 경우 다시 jwtService에서 정보를 받아와 확인.
                const user_info = jwtService.getUserInfo()
                if(user_info && user_info.stay) {
                    const _this = this;
                    document.onclick = function () {
                        _this.timeout = LOGOUT_TIME + 1;
                    };

                    document.onkeydown = document.onclick;

                    _this.tick();
                }
            }
        },
        set_logout_tick(isRun = false) {
            if (isRun) this.start_logout_tick();
            else clearTimeout(this.tick_no);
        },
        tick() {
            const _this = this;
            if (this.user_info.role == this.role_student) {
                this.logout_guide_string = "자동 로그아웃 남은 시간 : " + num_format(Math.floor(this.timeout / 60)) + ":" + num_format(Math.floor(this.timeout % 60));
            }

            _this.tick_no = setTimeout(function () {
                _this.timeout--;
                if (_this.timeout == 0) {
                    _this.$store.dispatch("logout");
                    _this.$router.push("/login");
                } else if (!jwtService.getAccessToken() || !jwtService.getRefreshToken()) {
                    clearTimeout(this);
                } else _this.tick();
            }, 1000);
        },
        logout: function () {
            // if (this.user_info.role != this.role_student && this.user_info?.checkin_time) {
            //     this.$ModalManager
            //         .showdialog({
            //             title: "퇴근 기록이 없습니다.",
            //             msg: "퇴근 기록을 등록 후 로그아웃 하시겠습니까?",
            //             buttons: {
            //                 ok: "등록 및 로그아웃",
            //                 yes: "로그아웃",
            //                 no: "취소",
            //             },
            //         })
            //         .then((res) => {
            //             if (res.result == "no") return;
            //             if (res.result == "ok") this.on_teacher_action_pin(true);
            //             this.$store.dispatch("logout");
            //             this.$router.push("/login");
            //         });
            // } else {
            this.$ModalManager.showdialog({ msg: "정말 로그아웃 하시겠습니까?", buttons: { yes: "로그아웃", no: "취소" } }).then(async (res) => {
                if (res.result == "yes") {
                    if (this.user_info.role != this.role_student && this.user_info?.checkin_time) {
                            await this.on_teacher_action_pin(true);
                            this.$store.dispatch("logout");
                            this.$router.push("/login");
                        // });
                    } else {
                        this.$store.dispatch("logout");
                        this.$router.push("/login");
                    }
                }
            });
            // }
        },

        d_clock: function () {
            var d = new Date();
            var n = new Date();

            var week = ["(일) ", "(월) ", "(화) ", "(수) ", "(목) ", "(금) ", "(토) "];

            var today = new Date().getDay();
            var getTodayLabel = week[today];

            var theMonth = d.getMonth() + 1;
            if (theMonth < 10) {
                theMonth = "0" + theMonth;
            } else {
                theMonth = "" + theMonth;
            }

            var theDate = d.getDate();
            if (theDate < 10) {
                theDate = "0" + theDate;
            } else {
                theDate = "" + theDate;
            }

            var theHours = d.getHours();
            if (theHours > 12) {
                theHours = theHours - 12;
                theHours = "오후 " + theHours.toString();
            } else {
                theHours = "오전 " + theHours.toString();
            }

            var theMins = d.getMinutes();
            if (theMins < 10) theMins = "0" + theMins;

            var currentDate = d.getFullYear() + "년 " + theMonth + "월 " + theDate + "일";
            var currentTime = theHours + ":" + theMins;
            this.date_time_str = currentDate + getTodayLabel + currentTime;
        },
        move_account_page() {
            const user_info = jwtService.getUserInfo();

            let path;

            if (user_info && (user_info.role || user_info.role === 0)) {
                switch (user_info.role) {
                    case ROLE.STUDENT:
                        path = "/student/account";
                        break;
                    case ROLE.MANAGER: 
                        path = "/manager/account";
                        break;
                    case ROLE.FRONT:
                        path = "/front/account";
                        break;
                    case ROLE.CONSULT_TEACHER:
                        path = "/consulting/account";
                        break;
                    case ROLE.ADMIN:
                    case ROLE.COMPANYADMIN: 
                    case ROLE.FRANCHISEADMIN:                       
                        path = "/admin/account";
                        break;
                }
            }

            if (path) {
                this.$router.push(path);
            }
        },
        show_account_buttons() {
            const user_info = jwtService.getUserInfo();
            const last_status = user_info?.login_result?.login_info?.last_status
            const stay = (last_status == SCHEDULE_API.ACTION_TYPE.CHECKIN || last_status == SCHEDULE_API.ACTION_TYPE.COMEBACK)
            if (user_info && (user_info.role || user_info.role === 0)) {
                // 학생일 경우 입실 되지 않았을 경우 표출 하지 않음.
                if (user_info.role === ROLE.STUDENT && last_status !== undefined && !stay) {
                    this.account_buttons_disable = false;
                    return;
                }
            }
            this.account_buttons_disable = true;
        },
        async on_teacher_action_pin(logout = false) {
            const user_info = this.user_info;
            const member_no = this.member_info?.member_no;
            if (!member_no || !user_info || user_info?.role == this.role_student) return;
            const checkin = user_info?.checkin_time ? true : false;
            const time = new Date();
            let ret;
            // if (modal) {
                let str = "출근";
                if (checkin) str = "퇴근";
                ret = await this.$ModalManager.showdialog({
                    title: str + " 등록을 하시겠습니까?",
                    msg: date_format(time, "hh시 mm분") + "으로 " + str + "기록이 작성됩니다.",
                    buttons: {
                        yes: checkin ? "퇴 근" : "출 근",
                        no: "취 소",
                    },
                });
            // }

            let checkin_time = user_info.checkin_time;
            if (ret?.result == "yes") {
                const action_type = checkin ? SCHEDULE_API.ACTION_TYPE.CHECKOUT : SCHEDULE_API.ACTION_TYPE.CHECKIN;
                let res = await SCHEDULE_API.teacher_add_action_log(member_no, action_type, time);
                if (res?.result == "done") {
                    checkin_time = checkin ? false : date_format(time, "hh:mm");
                }
            }else if(ret?.result == "no" && !checkin){
                checkin_time = false;
            }

            if(!logout){
                user_info.checkin_time = checkin_time;
                jwtService.setUserInfo(user_info);
                this.user_info = jwtService.getUserInfo();
            }
        },
    }
};
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
    background-color: #f2f2f2;
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 90;
}

.header_in {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_logo {
    display: flex;
    align-items: center;
    width: 435px;
}
.header_logo .logo {
    margin-right: 20px;
    width: 162px;
    height: 40px;
}
.header_logo .header_date {
    margin-top: auto;
    font-size: 17px;
    color: #6c6c6c;
}
.header_searcharea {
    width: 735px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-left: 45px;
    margin-right: 46px;
    box-sizing: border-box;
}
.header_searcharea_logo {
    display: flex;
    align-items: center;
    width: 78px;
    height: 19px;
    font-size: 16px;
    color: #6c6c6c;
}
.bell_logo {
    width: 15px;
    height: 19px;
    margin-right: 3px;
}
.header_acount {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.acount_welcome {
    padding-right: 10px;
}
.acount_welcome p {
    font-size: 16px;
    color: #6c6c6c;
    margin: 0;
}
.acount_welcome p span {
    font-size: 17px;
    font-weight: bold;
}
.header_acount_button {
    display: flex;
    align-items: center;
}
.header_acount_button > div {
    width: 78px;
    height: 25px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    text-align: center;
    margin-left: 5px;
}
.header_acount_button > div:nth-child(1) {
    margin: 0;
}
.header_acount_button > div a {
    line-height: 25px;
    font-size: 16px;
    color: #1e3560;
}
.header_acount_button > div button {
    background:transparent;
    width:100%;
    height: 100%;
    font-size: 16px;
    color: #1e3560;
}

.header_acount_button > div:nth-child(1) {
    position: relative;
}

@keyframes updown {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(2px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-2px);
    }
    100% {
        transform: translateY(0px);
    }
}

.moving_notify {
    position: absolute;
    top: 32px;
    left: -75px;
    width: 229px;
    height: 24px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
    background-color: #61c1be;
    border-radius: 15px;
    animation-name: updown;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    user-select: none;
    cursor: pointer;
}
.moving_notify img {
    width: 8px;
    height: 8px;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}

div#top_header_admin {
    position: relative;
    left: -100px;
    top: 5px;
    button {
        width: fit-content;
        height: fit-content;
        margin: 5px;
        padding: 3px 10px;
        border: 0px;
        border-radius: 15px;
        background: #fff;
        border: 1px solid #ccc;
        font-size: 15px;
        color: #333;
    }
}
</style>
