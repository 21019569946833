const routes = [
    {
        path: "/consulting",
        component: () => import("@/apps/AppConsulting"),
        redirect: "/consulting/main",
        children: [
            {
                path: "/consulting/main",
                name: "/consulting/main",
                component: () => import("@/components/consulting_teacher/main/ConsultingMain")
            },
            {
                path: "/consulting/student",
                name: "/consulting/student",
                component: () => import("@/components/consulting_teacher/student/ConsultingStudentListLayout")
            },
            {
                path: "/consulting/student_schedule",
                name: "/consulting/student_schedule",
                component: () => import("@/components/front/FrontSearch")
            },
            {
                path: "/consulting/lookup",
                name: "/consulting/lookup",
                component: () => import("@/components/consulting_teacher/lookup/ConsultingLookupLayout")
            },
            {
                path: "/consulting/report",
                name: "/consulting/report",
                component: () => import("@/components/consulting_teacher/report/ConsultingReport")
            },
            {
                path: "/consulting/planner",
                name: "/consulting/planner",
                component: () => import("../components/manager/ManagerPlanner.vue")
            },
            {
                path: "/consulting/record",
                name: "/consulting/record",
                component: () => import("../components/consulting_teacher/student_grade/ConsultingStudentRecordLayout")
            },
            {
                path: "/consulting/score",
                name: "/consulting/score",
                component: () => import("@/components/front/FrontBadScore")
            },
            {
                path: "/consulting/employee",
                name: "/consulting/employee",
                component: () => import("../components/manager/ManagerEmployee.vue")
            },
            {
                path: "/consulting/sms",
                name: "/consulting/sms",
                component: () => import("@/components/admin/sms")
            },
            {
                path: "/consulting/center",
                name: "/consulting/center",
                component: () => import("@/components/common/CenterNotify/CenterNotify")
            },
            {
                path: "/consulting/schedule",
                name: "/consulting/schedule",
                component: () => import("@/components/consulting_teacher/schedule/ConsultingSchoolScheduleLayout")
            },
            {
                path: "/consulting/notice",
                name: "/consulting/notice",
                component: () => import("@/components/common/SendNotice/SendNotice")
            },
            {
                path: "/consulting/account",
                component: () => import("@/components/manager/account/ManagerAccountEdit.vue")
            },
            {
                path: "/consulting/employee_schedule",
                component : ()=> import("@/components/common/EmployeeSchedule.vue"),
            },
        ]
    }
];

export default routes;
