import { ApiReq } from "@/components/manager/ManagerUtils";
import { date_format } from "@/js/util/math";
import ApiService from "@/services/api.service"
import { SCHEDULE_API } from "@/services/exapi.service.js"

const LATE_PANELTY_DESCRIPTION = "첫 입실 시간 10분, 식사/휴식 복귀 5분 이상 벌점 부여 (가산 벌점 최대 -15점)"
// API 벌점 Data
const PANELTY_DATA = {
    TEACHER_ID : "SYSTEMDAEMON",
    get_panelty_consulting_message(point) {
        return "벌점이 " + point + "점을 넘어 자동으로 등록되었습니다."
    },

    point_data : {
        under30_data : {
            description : LATE_PANELTY_DESCRIPTION,
            type : SCHEDULE_API.ACTION_TYPE.PANELTY_UNDER30,
            point : -2,
            name : "30분 이하 지각",
            teacher_check : false,
        },
        upper30_data : {
            description : LATE_PANELTY_DESCRIPTION,
            type : SCHEDULE_API.ACTION_TYPE.PANELTY_UPPER30,
            point : -3,
            name : "30분 이상 지각",
            teacher_check : true,
        },
        overhour_data : {
            description : LATE_PANELTY_DESCRIPTION,
            type : SCHEDULE_API.ACTION_TYPE.PANELTY_OVERHOUR,
            point : -4,
            name : "1시간 이상 지각",
            teacher_check : true,
        },
        over2hour_data : {
            description : LATE_PANELTY_DESCRIPTION,
            type : SCHEDULE_API.ACTION_TYPE.PANELTY_OVER2HOUR,
            point : -5,
            name : "2시간 이상 지각",
            teacher_check : true,
        },
        over3hour_data : {
            description : LATE_PANELTY_DESCRIPTION,
            type : SCHEDULE_API.ACTION_TYPE.PANELTY_OVER3HOUR,
            point : -10,
            name : "3시간 이상 지각",
            teacher_check : true,
        },
    },

    async add_panelty_point(student_id, center_no, panelty_type, reason, data = null) {

        const panelty = await this.get_panelty_data(panelty_type, center_no);
        
        if(!panelty) {
            return false;
        }

        let req = {
            student_id : student_id,
            teacher_id : PANELTY_DATA.TEACHER_ID,
            // point : panelty.point,
            // content : panelty.name,
            type_no : panelty.no,
            reason : reason,
        }

        if(data) {
            req.data = {
                schedule_time : data.schedule_time,
                actual_time : data.actual_time,
            }
        }

        const res = await this.request_panelty_point(req);
        return res
    },
    
    async get_panelty_data(type, center_no) {
        let panelty;
        await new ApiReq("/point/types", { ctr: center_no })
            .setResultCallback(res => {
                panelty = res.find(e => e.panelty_code == type);
                if (panelty) {
                    panelty.type = type;
                    panelty.teacher_check = type == SCHEDULE_API.ACTION_TYPE.PANELTY_UNDER30 ? false : true;
                } else panelty = false;
            })
            .get();
        // Object.entries(PANELTY_DATA.point_data).forEach(item => {
        //     if(type == item[1].type) {
        //         panelty = item[1]
        //     }
        // })
        return panelty
    },

    async request_panelty_point(req, url = "/point") {
        let student_info = null;
        let student_point = 0;
        const current_point = Number(req.point)
        await new ApiReq("/student/" + req.student_id + "/info")
            .setResultCallback(data => {student_info = data;})
            .get()
        if(student_info) {
            student_point = student_info.point ? Number(student_info.point) : 0;
        }

        let res = await new ApiReq(url, req).post()
        if(!res) {
            return;
        }
        
        let panelty_point = 0
        if(student_point > -50 && (student_point + current_point) <= -50) {
            panelty_point = 50
        }
        // 2024-07-18 알림 벌점 50점 이상으로 변경.
        // else if(student_point > -40 && (student_point + current_point) <= -40) {
        //     panelty_point = 40
        // }
        // else if(student_point > -30 && (student_point + current_point) <= -30) {
        //     panelty_point = 30
        // }
        if(panelty_point == 0) {
            return false;
        }

        const consulting_req = {
            student_id : req.student_id,
            message : this.get_panelty_consulting_message(panelty_point),
            request_datetime : null,
            type_no : null,
            teacher_id : null,
            requester_id : this.TEACHER_ID
        }
        res = await new ApiReq("/counseling", consulting_req).post()
        return res;
    }
}
export default PANELTY_DATA;