<template>
    <div id="must_check">
        <div class="must_check_in">
            <div>
                <h1>
                    오늘 주요 사항
                </h1>
                <p>
                    오늘의 주요 사항을 확인 후 체크해주세요.
                </p>
            </div>
            <div>
                <h2>
                    상벌점 부과 내역
                </h2>
                <template v-if="login_check_notice.point_content && login_check_notice.point_content.length !== 0">
                    <div class="webkitscroll point_content">
                        <label v-for="(point, point_idx) in login_check_notice.point_content" :key="point_idx">
                            <dl>
                                <dt>
                                    <input type="checkbox" class="chk" v-model="check_data.point[point_idx].check" >{{point.title}}
                                </dt>
                                <dd>
                                    {{point.point}}점 {{point.content}}
                                </dd>
                            </dl>
                        </label>
                    </div>
                </template>
                <div v-else class="empty">
                    없음.
                </div>
            </div>
            <div>
                <h2>
                    상담 예약
                </h2>
                <template v-if="login_check_notice.consulting_content && login_check_notice.consulting_content.length !== 0">
                    <ul class="webkitscroll consulting_content">
                        <label v-for="(consulting, consulting_idx) in login_check_notice.consulting_content" :key="consulting_idx">
                            <li>
                                <input type="checkbox" class="chk" v-model="check_data.consulting[consulting_idx].check">
                                {{consulting.title}} ({{consulting.teacher}}) {{consulting.time}}
                            </li>
                        </label>
                    </ul>
                </template>
                <div v-else class="empty">
                    없음.
                </div>
            </div>
            <div>
                <h2>
                    공지 사항
                </h2>
                <template v-if="login_check_notice.notice_content && login_check_notice.notice_content.length !== 0">
                    <ul class="webkitscroll notice_content">
                        <li v-for="(notice, notice_idx) in login_check_notice.notice_content" :key="notice_idx">
                            <label>
                                <div class="notice_check_box_title">
                                    <input type="checkbox" class="chk" v-model="check_data.notice[notice_idx].check">
                                    {{notice.title}}
                                </div>
                                <div class="notice_check_box_content">
                                    {{notice.content}}
                                </div> 
                            </label>
                            <button class="notice_content_button" @click="on_check_notice_content_show(notice, notice_idx)">내용 더보기</button>
                        </li>
                    </ul>
                </template>
                <div v-else class="empty">
                    없음.
                </div>
            </div>
            <article :class="{disable_before_check : !all_check}">
                <input type="button" value="확 인" class="cancel" @click="close()">
            </article>
        </div>
    </div>
</template>

<script>
import ApiService from "@/services/api.service"
import LoginData from "@/components/student/data/StudentLoginData"
import { date_format } from '@/js/util/math'
import jwtService from '@/services/jwt.service'
import ConsultingBigSizeTextModal from '@/components/consulting_teacher/modal/ConsultingBigSizeTextModal.vue'
export default {
    props : {
        attr : Object,
    },
    data () {
        return { 
            check_data : {
                point : [],
                consulting : [],
                notice : [],
            },
            login_check_notice : {
                point_content : [],
                consulting_content : [],
                notice_content : [],
            },
            update_after_login_data_check : false,
            update_after_notice_check : false,
        }
    },
    computed : {
        all_check : function(){
            if(!this.update_after_login_data_check || !this.update_after_notice_check) {
                return false
            }
            // 모든 데이터에 대해 false가 없음을 체크.
            const point_check = this.check_data.point.every(item => {
                return item.check
            })
            const consulting_check = this.check_data.consulting.every(item => {
                return item.check
            })
            const notice_check = this.check_data.notice.every(item => {
                return item.check
            })
            return (point_check && consulting_check && notice_check)
        }
    },
    methods : {
        close() {
            this.$ModalManager.close(this)
        },
        on_check_notice_content_show(notice, index) {
            this.$ModalManager.show(ConsultingBigSizeTextModal, {
                title : notice.title,
                msg : notice.content,
                write_mode : false,
            }).then(res => {
                this.check_data.notice[index].check = true
            })
        },
        request_after_login_check() {
            // 공지 사항 req
            ApiService.call(ApiService.GET, "/my/check/after-login").then(res => {
                if(res && res.data) {
                    this.response_after_login_check(res.data)
                    
                }
                this.update_after_login_data_check = true
            }).catch( err => {
                this.update_after_login_data_check = true
            })
        },
        request_student_notice() {
            const param = {
                date : date_format(new Date(), "YYYY-MM-DD"),
                ctr : jwtService.getUserInfo().center_no,
            }
            ApiService.call(ApiService.GET, "/notices/student", param).then(res => {
                if(res && res.data) {
                    this.response_student_notice(res.data)
                }
                this.update_after_notice_check = true
            }).catch(err => {
                this.update_after_notice_check = true
            })
        },
        response_after_login_check(res) {
            // response를 받으면 object내 key는 2개 생성. 다만, 데이터는 없을 수 있음.
            const after_login_content = LoginData.convert_notice_data_to_login_modal_data(res)
            this.login_check_notice.point_content = after_login_content.point_content
            this.login_check_notice.consulting_content = after_login_content.consulting_content

            if(this.attr && this.attr.point) {
                let late_point_content = {
                    title : this.attr.point.name,
                    point : this.attr.point.point,
                    content : "(" + LoginData.point_imposed  +")",
                }
                this.login_check_notice.point_content.push(late_point_content)
            }
            if(this.login_check_notice.point_content)
                this.login_check_notice.point_content.forEach(item => {
                    this.check_data.point.push({description : item.title, check : false})
                })
            if(this.login_check_notice.consulting_content)
                this.login_check_notice.consulting_content.forEach(item => {
                    this.check_data.consulting.push({description : item.title, check : false})
                })
        },
        response_student_notice(res) {
            res.forEach(item => {
                this.login_check_notice.notice_content.push(item)
                this.check_data.notice.push({description : item.title, check : false})
            })
        },
    },
    created () {
        this.request_after_login_check()
        this.request_student_notice()
    }
}
</script>

<style scoped lang="scss">

#must_check {
    width: 540px;
    border-radius: 15px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    padding: 30px 0;
    text-align: center;
    color: #6c6c6c;
    .must_check_in {
        width: 480px;
        margin: 0 auto;
        user-select: none;
        > div:nth-child(1) h1{
            font-size: 28px;
            color: #1e3560;
            font-weight: bold;
            margin-bottom: 10px;
        }
        > div:nth-child(2),
        > div:nth-child(3),
        > div:nth-child(4) {
            text-align: left;
            font-size: 18px;
            margin: 5px 0;
            padding: 5px 0;
            border-top: 1px solid #9c9c9c;
        }
        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #1e3560;
        }
        .empty {
            margin: 20px 0px 20px 0px; 
            text-align: center;
        }
        .point_content, .consulting_content {
            overflow-y : scroll;
            max-height: 200px;
        }
        dl dt,
        dl dd,
        ul li {
            margin: 5px 0;
        }
        dl dt {
            font-weight: bold;
        }
        dl dd {
            margin-left: 20px;
        }
        input[type="checkbox"] {
            margin-right: 5px;
        }
        article {
            margin-top: 25px;
        }
        article input {
            width: 170px;
            height: 40px;
            border-radius: 20px;
            border: none;
            background-color: #1e3560;
            color: #fff;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            cursor: pointer;
        }
        .disable_before_check input {
            pointer-events: none;
            background-color: #D9D9D9;
        }
        .notice_content {
            max-height: 200px;
            padding-right: 10px;
            overflow-y : scroll;
            width: 100%;
            .notice_check_box_title {
                font-weight: bold;
            }
            .notice_check_box_content {
                text-overflow: ellipsis;
                /* white-space: nowrap; */
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .notice_content_button {
                font-size : 18px;
                padding: 5px 0;
                width : 100%;
                color : #fff;
                background: var(--color-sparta);
                border-radius: 15px;
                margin : 5px 0;
                align-self: center;
            }
        }
    }
}


// .must_check {
//     width: 170px;
//     height: 40px;
//     border: none;
//     background-color: #1e3560;
//     color: #fff;
//     font-size: 16px;
//     text-align: center;
//     line-height: 40px;
//     border-radius: 20px;
//     margin-top: 20px;
//     cursor: pointer;
// }
</style>
