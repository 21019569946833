import JwtService from "@/services/jwt.service"
import ApiService from "@/services/api.service"
import { ROLE } from "@/js/util/common"
import { SCHEDULE_API } from "@/services/exapi.service.js"

const state = {

}

const getters = {
    userinfo()
    {
        return JwtService.getUserInfo();
    }
}

const mutations = {

}

const actions = {
    login(context, login_info)
    {
        return new Promise((resolve, reject) =>{
            ApiService.call(ApiService.POSTWITHOUTAUTH, "/login", login_info)
            .then(async function(res) {
                if(res.data.result != undefined && res.data.result == true)
                {
                    const access_token = res.data.access_token;
                    var base64Payload = access_token.split('.')[1];
                    var payload = atob(base64Payload);
                    var decode = JSON.parse(payload.toString());
                    
                    var user_info = {
                        userid : decode.sub,
                        role : decode.typ,
                        center_no : decode.ctr,
                        stay : res.data.stay,
                        base_center_no : decode.ctr,
                    };

                    // console.log("a", user_info);
                    JwtService.setAccessToken(res.data.access_token);
                    JwtService.setRefreshToken(res.data.refresh_token);
                    
                    switch (user_info.role) {
                        // 학생 로그인시 체크인 1회만 동작.
                        case ROLE.STUDENT:
                            {
                                let res = await action_login()
                                user_info.login_result = res
                            }
                            break;
                        case ROLE.FRONT:
                        case ROLE.CONSULT_TEACHER:
                        case ROLE.MANAGER:
                        case ROLE.ADMIN:
                        case ROLE.FRANCHISEADMIN:
                        case ROLE.COMPANYADMIN:
                            {
                                let res = await SCHEDULE_API.teacher_action_checkin();
                                user_info.checkin_time = res;
                            }
                            break;
                    }
                    
                    JwtService.setUserInfo(user_info);

                    resolve(res);
                } else {
                    reject(res);
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
        
        async function action_login() {
            const myinfo = (await ApiService.call(ApiService.GET, "/my/info")).data
            const student_info =
            {
                no : myinfo.member_no,
                name : myinfo.name,
                center_name : myinfo.center_name,
                center_phone_number_sms : myinfo.center_phone_number_sms,
                expiration_datetime : myinfo.expiration_datetime
            };
            if(myinfo.parent_phone_number) {
                student_info.phone = myinfo.parent_phone_number
            }
            
            return await SCHEDULE_API.action_login(student_info, new Date());
        }
    },

    async fingerprint_login(context, login_info)
    {
        const access_token = login_info.access_token;
        var base64Payload = access_token.split('.')[1];
        var payload = atob(base64Payload);
        var decode = JSON.parse(payload.toString());

        var user_info = {
            userid : decode.sub,
            role : decode.typ,
            center_no : decode.ctr,
            stay : login_info.stay,
            base_center_no : decode.ctr,
        };
        
        JwtService.setAccessToken(login_info.access_token);
        JwtService.setRefreshToken(login_info.refresh_token);

        switch (user_info.role) {
            // 학생 로그인시 체크인 1회만 동작.
            case ROLE.STUDENT:
                {
                    let res = await action_login()
                    user_info.login_result = res
                }
                break;
            case ROLE.FRONT:
            case ROLE.CONSULT_TEACHER:
            case ROLE.MANAGER:
            case ROLE.ADMIN:
            case ROLE.FRANCHISEADMIN:
            case ROLE.COMPANYADMIN:
                {
                    let res = await SCHEDULE_API.teacher_action_checkin();
                    user_info.checkin_time = res;
                }
                break;
        }

        JwtService.setUserInfo(user_info);

        async function action_login() {
            const myinfo = (await ApiService.call(ApiService.GET, "/my/info")).data
            const student_info =
            {
                no : myinfo.member_no,
                name : myinfo.name,
                center_name : myinfo.center_name,
                center_phone_number_sms : myinfo.center_phone_number_sms,
                expiration_datetime : myinfo.expiration_datetime
            };
            if(myinfo.parent_phone_number) {
                student_info.phone = myinfo.parent_phone_number
            }
            
            return await SCHEDULE_API.action_login(student_info, new Date());
        }
    },
    
    logout(context)
    {
        if(JwtService.getAccessToken() == "") return;

        const user_info = JwtService.getUserInfo()
        // 학생 로그아웃시 스케쥴 action logout 동작.
        if(user_info?.role == ROLE.STUDENT) {
            action_logout();
        }
        const req = {
            access_token : JwtService.getAccessToken(),
            refresh_token : JwtService.getRefreshToken(),
        }
        ApiService.call(ApiService.POST, "/logout", req)

        JwtService.deleteAccessToken();
        JwtService.deleteRefreshToken();
        JwtService.deleteUserInfo();
        JwtService.deleteCompanyAdminCenterInfo();

        function action_logout() {
            ApiService.call(ApiService.GET, "/my/info").then(res => {
                if(res && res.data) {
                    const myinfo = res.data
                    const student_no = myinfo.member_no
                    SCHEDULE_API.add_action_log(student_no, new Date(), SCHEDULE_API.ACTION_TYPE.LOGOUT);
                }
            })
        }
    },

    verify(context, page_role)
    {

        const userinfo = JwtService.getUserInfo();
        if(userinfo == undefined || (page_role != undefined && userinfo.role != page_role))
            return Promise.reject();

        const token_info = {
            id : JwtService.getUserInfo()?.userid,
            token : JwtService.getAccessToken(),
        }

        return new Promise((resolve, reject) => {
            ApiService.call(ApiService.POSTWITHOUTAUTH, "/verify", token_info)
            .then((res) =>
            {
                if(res.data.result == false)
                    reject("not valid token");
                else
                    resolve("valid token");
                    
            })
            .catch((err)=>{
                reject("call error");
            });
        });
    },

    myinfo(context)
    {
        return new Promise((resolve, reject) => {
            const config = {
                header : {
                    "Content-Type": 'application/json',
                    "Authorization": 'Bearer ' + JwtService.getAccessToken(),
                }
            };

            ApiService.call(ApiService.GET, "/my/info", config)
            .then((res)=>{
                resolve(res);
            })
            .catch(error=>{
                reject(error);
            });
        });
    },

    delete_remote_token(context, user_id)
    {
        const config = {
            header : {
                "Content-Type": 'application/json',
            }
        };

        return new Promise((resolve, reject) => {
            ApiService.call(ApiService.DELETE, "/delete-token/" + user_id, config)
            .then((res)=>resolve("success"))
            .catch((err)=>reject("failed"))
        });
    }



}

export default {
    state, actions, getters, mutations
}