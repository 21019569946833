<template>
    <section>
        <div id="header">
            <h1>상벌점 취소</h1>
        </div>
        <table>
            <tr>
                <td>일시</td>
                <td>{{ panelty.created_at }}</td>
            </tr>
            <tr>
                <td>내용</td>
                <td>{{ panelty.content }}</td>
            </tr>
            <tr>
                <td>부과 상벌점</td>
                <td>{{ panelty.point }}</td>
            </tr>
            <tr>
                <td>사유</td>
                <td>{{ panelty.reason }}</td>
            </tr>
            <tr>
                <td>상벌점 취소 사유</td>
                <td><input type="text" v-model="cancel_reason" placeholder="상벌점 취소 사유를 입력하세요." /></td>
            </tr>
        </table>
        <div id="bottom">
            <button @click="this.$ModalManager.close(this)">닫 기</button>
            <button style="background: rgb(203, 50, 8)" @click="cancel_panalty">상벌점 취소</button>
        </div>
    </section>
</template>

<script>
import { EXAPI } from "@/services/exapi.service.js"
import jwtService from "@/services/jwt.service";
export default {
    props: {
        panelty: Object
    },

    methods: {
        cancel_panalty() {


            var user_info = jwtService.getUserInfo();

            if(this.cancel_reason.trim() == "")
            {
                this.$ModalManager.showdialog("취소 사유를 입력해주세요.");
                return;
            }

            this.$ModalManager.showdialog("정말 취소하시겠습니까?", { yes: "예", no: "아니오" }).then((res) => {
                if (res.result == "yes") {
                    this.$ModalManager.showdialog("취소되었습니다.").then(() => {
                        const req = {
                            panelty_no : this.panelty.no,
                            cancel_reason : this.cancel_reason,
                            canceler_id : user_info.userid,
                        };

                        EXAPI.Call("cancel_panelty", req);

                        this.$ModalManager.do_ok(this);
                    });
                }
            });
        }
    },

    data() {
        return {
            cancel_reason: ""
        };
    }
};
</script>

<style lang="scss" scoped>
section {
    width:560px;
    box-sizing: border-box;
    background: #fff;
    padding: 30px;
    border-radius: 30px;

    h1 {
        font-size: 25px;
        color: #1e3560;
        font-weight: bold;
        text-align: left;
    }

    table {
        margin-top: 20px;
        width: 500px;
        border-spacing: 0;
        
        tr:nth-of-type(1){
            td{
                border-top:1px solid #9c9c9c;    
            }
        }
        td {
            height: 40px;
            border-bottom:1px solid #9c9c9c;
            &:nth-of-type(1) {
                width: 120px;
                background:#f2f2f2;
                color:#1e3560;
                font-weight: bold;
            }
            &:nth-of-type(2) {
                text-align: left;
                padding-left:20px;
                word-break: break-all;
                color:#6c6c6c;
            }
        }
        input {
            border:none;
            outline: none;
            cursor:text;
            width: 100%;
            height:100%;
        }
    }

    div#bottom {
        margin-top: 20px;
        width: 100%;
        button {
            width: 170px;
            height: 40px;
            color: #fff;
            border-radius: 20px;
            border: none;
            font-size: 16px;
            background-color: #9c9c9c;
            margin: 0 20px;
        }
    }
}
</style>
