<template>
    <section class="modal_main">
        <div class="title_bar">
            <h1 v-if="this.isFix">상벌점 종류 수정</h1>
            <h1 v-else>상벌점 종류 추가</h1>
            <input type="checkbox" class="star_button" v-model="this.favorite" />
        </div>
        <div class="content_box">
            <div>사유 <input type="text" @input="this.textInput" :value="this.name" placeholder="추가하실 상벌점사유를 입력해주세요." /></div>
            <div>상벌점 <input type="number" @keypress="this.onlyNumber" @input="this.numberInput" :value="this.point" /> &nbsp;벌점 <input type="checkbox" v-model="this.negative"/></div>
            <div>
                <p>상벌점 설명</p>
                <textarea v-model="this.description"></textarea>
            </div>
        </div>
        <div class="btn_bar">
            <button @click="this.onCancel()">취 소</button>
            <await-button class="blue_back" v-if="this.isFix" @click="this.onFix()">수 정</await-button>
            <await-button class="blue_back" v-else @click="this.onAdd()">추 가</await-button>
        </div>
    </section>
</template>

<script>
import { ApiReq } from "@/components/manager/ManagerUtils";
export default {
    name: "BadScoreTypeAddModal",
    props: {
        isFix: {
            type: Boolean,
            default: false,
        },
        typeData: Object,
        centerNo: String,
    },
    data() {
        return {
            description: "",
            name: null,
            point: 0,
            favorite: false,
            negative: true,
        };
    },
    watch:{
        negative:{
            handler(v){
                this.point = Math.abs(this.point) * (v ? -1 : 1)
            }
        }
    },
    methods: {
        emptyCheck() {
            if (!this.name) {
                this.$ModalManager.showdialog("사유를 입력해주세요.");
                return false;
            }
            if (!this.point) {
                this.$ModalManager.showdialog("상벌점을 입력해주세요.");
                return false;
            }
            if (!this.description.trim()) {
                this.$ModalManager.showdialog("상벌점 설명을 입력해주세요.");
                return false;
            }
            if (this.isFix && this.typeData) {
                const data = this.typeData;
                if (this.point == Number(data.point) && this.description.trim() == data.description && this.name.trim() == data.name && (this.favorite ? 1 : 0) == data.favorite) {
                    this.$ModalManager.showdialog("수정사항이 없습니다.");
                    return false;
                }
            }
            return true;
        },
        createParam() {
            return {
                center_no: this.centerNo,
                name: this.name.trim(),
                point: Number(this.point),
                description: this.description.trim(),
                favorite: this.favorite ? 1 : 0,
            };
        },
        async onAdd() {
            if (this.emptyCheck()) {
                let ret = await this.$ModalManager.showdialog({
                    title: "상벌점을 추가하시겠습니까?",
                    buttons: { ok: "추 가", no: "취 소" },
                });

                if (ret?.result == "ok") {
                    new ApiReq("/point/type", this.createParam())
                        .setResultCallback((res) => {
                            if (res.result) {
                                this.$ModalManager.close(this, true);
                            } else return false;
                        })
                        .setCompleteMsg("상벌점이 추가 되었습니다.")
                        .setFailMsg("상벌점 추가에 실패하였습니다.")
                        .post();
                }
            }
        },
        async onFix() {
            if (this.emptyCheck()) {
                let ret = await this.$ModalManager.showdialog({
                    title: "상벌점을 수정하시겠습니까?",
                    buttons: { ok: "수 정", no: "취 소" },
                });
                if (ret?.result == "ok") {
                    new ApiReq("/point/type/" + this.typeData.no, this.createParam())
                        .setResultCallback((res) => {
                            if (res.result) {
                                this.$ModalManager.close(this, true);
                            } else return false;
                        })
                        .setCompleteMsg("상벌점이 수정 되었습니다.")
                        .setFailMsg("상벌점 수정에 실패하였습니다.")
                        .put();
                }
            }
        },
        onCancel() {
            this.$ModalManager.close(this);
        },
        onlyNumber(e) {
            e = e ? e : window.event;
            const charCode = e.which ? e.which : e.keyCode;
            if (charCode < 48 || charCode > 57) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        numberInput(e) {
            let value = e.target.value || 0;
            // 빈값이 아니면
            if (value) {
                value = Number(value);
                // 0 이 아니면
                if (value) {
                    value = Math.abs(value) * (this.negative ? -1 : 1)
                }else{
                    e.target.value = value;
                }
            }
            this.point = value;
        },
        textInput(e) {
            if (!e.target.value.trim()) e.target.value = null;
            this.name = e.target.value;
        },
    },
    created() {
        if (!this.centerNo) {
            this.$ModalManager.showdialog("등록된 센터번호가 없습니다");
            this.$ModalManager.close(this);
        } else if (this.typeData) {
            this.point = Number(this.typeData.point);
            if(this.point > 0) this.negative = false;
            this.description = this.typeData.description;
            this.name = this.typeData.name;
            this.favorite = this.typeData.favorite == 1 ? true : false;
        }
    },
};
</script>

<style lang="scss" scoped>
.modal_main {
    width: 450px;
    height: 300px;
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: #6c6c6c;
    > div {
        width: 100%;
    }
    .title_bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .content_box {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        > div {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;
            &:nth-last-of-type(1) {
                display: block;
                flex: 3;
            }
        }
    }

    .btn_bar {
        text-align: center;
    }
    h1 {
        color: #1e3560;
        font-weight: bold;
        font-size: 24px;
    }

    input {
        margin-left:5px;
        height: 27px;
        text-align: left;
        border: 1px solid #9c9c9c;
        border-radius: 20px;
        font-family: "nanum";
        font-size: 16px;
        cursor: text;
        &[type="text"] {
            width: calc(100% - 35px);
            padding-left: 15px;
        }
        &[type="number"] {
            height: 25px;
            width: 50px;
            text-align: center;
        }
        &[type="checkbox"]{
            cursor:pointer;
            height:20px;
            width:20px;
        }
    }
    input.star_button {
        margin-right: 20px;
        display: flex;
        align-items: center;
        visibility: hidden;
        font-size: 30px;
        cursor: pointer;
        color: rgb(6, 139, 119);
        &:before {
            content: "\2606";
            visibility: visible;
        }
        &:checked:before {
            content: "\2605";
        }
    }
    button {
        width: 170px;
        height: 40px;
        border-radius: 20px;
        border: none;
        color: #fff;
        font-size: 16px;
        background-color: #9c9c9c;
    }
    button.blue_back {
        background-color: #1e3560;
        margin-left: 20px;
    }

    textarea {
        width: 100%;
        height: calc(100% - 20px);
        margin-top: 5px;
        padding: 5px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 16px;
        color: #6c6c6c;
        resize: none;
        white-space: pre-line;
        text-align: left;
        border: 1px solid #9c9c9c;
        outline: none;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
        }
        &::-webkit-scrollbar-track {
            background-color: #f2f2f2;
        }
    }
}
</style>