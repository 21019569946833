

export default{
    early_checkout_time : 15,

    week_type : {
        0 : "일",
        1 : "월",
        2 : "화",
        3 : "수",
        4 : "목",
        5 : "금",
        6 : "토"
    },

    student_type : {
        0 : "상담",
        1 : "등원대기",
        2 : "재원",
        3 : "쿠폰",
        4 : "홀드",
        9 : "퇴원",
        1001 : "VIP"
    },

    school_grade_type : {
        1 : 1,
        2 : 2,
        3 : 3,
        99 : "N"
    },
        
    major_type : {
        1 : "인문",
        2 : "자연",
        3 : "예체능",
        4 : "공시"
    },

    admission_type : {
        1 : "정시",
        2 : "수시ㆍ학종",
        4 : "교과",
        8 : "논술"
    },

    gender_type : {
        0 : "남",
        1 : "여"
    },
    
    subject_type : [
        "국어",
        "수학",
        "영어"
    ],
    
    student_status_type : {
        1 : "A",
        2 : "B",
        3 : "C"
    },

    preference_subject_type : {
        0 : "가장 자신있는 과목",
        1 : "가장 힘겨운 과목"
    },
    
    online_class_type : [
        "메가",
        "대성",
        "이투스",
        "시대",
        "EBS",
        "기타"
    ],

    study_management_type_for_ui : [
        [
            {value : "공부법(플래너) 관리"},
            {value : "시간 관리"},
            {value : "인강 관리"},
            {before_text : "모의고사 : ", value : "기출"},
            {value : "사설"}
        ],
        [
            {value : "국어 Q&A"},
            {value : "영어 Q&A"},
            {value : "수학 Q&A"},
            {value : "과학 Q&A"},
            {value : "영단어", input : true, input_before_text : " ( 추천교재", input_after_text : ")", input_width : "180px"}
        ],
        [
            {value : "기타사항", input : true, input_width : "710px"}
        ]
    ],

    student_late_point_type : {
        POINT_30_MIN_OR_LESS : 4,   // 30분 미만 지각
        POINT_ABOVE_30_MIN : 5,     // 30분 이상 지각
        POINT_ABOVE_1_HOUR : 6,     // 1시간 이상 지각
        POINT_ABOVE_2_HOUR : 7      // 2시간 이상 지각
    },

    // ★ 긴급성에 관한 기준  :  A – 중요하고 긴급성을 요하는 내용으로 상황 보고 후 1시간 이내 관리자 ( 팀장 급 이상 ) 보고 후 대응 하는 것이 원칙
    //                                           B – 중요하지만 긴급하지 않은 내용으로 상황 보고 후 그날 중 관리자에게 보고 후 2일 이내 대응 하면 되는 것이 원칙
    //                                           C – 중요하지는 않지만 학생의 센터 학습 생활에 영향을 미칠 만한 상황으로 관리자에게 보고 후 일주일 이내 대응하면 되는 것이 원칙
    student_report_priority_type : {
        0 : "A",
        1 : "B",
        2 : "C"
    },

    use_shuttle_bus:{
        0 : "이용안함",
        1 : "이용중"
    },

    seat_status_type : {
        empty : 0,  //퇴실
        seat : 1,   //착석
        outing : 2, //외출
        leave : 3   //무단퇴실
    },

    latest_in_out : {
        on : 0, //입실
        empty : 1,  //퇴실
        outing : 2, //외출
        in : 3, //복귀
        leave : 4   //무단퇴실
    },

    //좌석표 상태
    seatTableStatus : {
        none : 0,
        
        outside : 1,    //자리비움, 외출

        seat : 0x02,        //입실,
        injenium : 0x12,   //인재니움
        sleep : 0x22,      //수면
        planner_5min : 0x32,         //플래너 검사 5분전
        planner_start_check : 0x42,     //S-CHECK 완료
        planner_complete : 0x52,   //플래너 검사 완료
        
        absent : 4,     // 2024-04-16 변경 무단퇴실 > 인재니움룸 & 플래너 검사 완료
    },

    seatTableStatusStr : {
        0 : "none",
        1 : "자리비움 및 외출",

        0x02 : "입실",
        0x12 : "인재니움 사용 중",
        0x22 : "수면",
        0x32 : "플래너 검사 5분전",
        0x42 : "플래너 S-CHECK 완료",
        0x52 : "플래너 검사 완료",
        
        4 : "무단퇴실",
    },

    planer_finish_check_type :{
        0 : "최상",
        1 : "상",
        2 : "중",
        3 : "하",
        4 : "최하"
    },

    planer_alert_value : 2.5,    //상담알림 발생해야하는 기준 값 (플래너 Finish Check)

    important_report_status : {
        done : -1,      //상담T가 작성한 대응 내용을 관리T가 확인한 상태
        report : 0,     //관리T가 학생 보고 알림을 작성한 상태
        response : 1    //상담T가 학생 보고 알림을 보고 대응 내용을 작성한 상태
    },

    consulting_requester_type : {
        0 : "학생",
        1 : "학부모",
        2 : "센터"
    },

    meterial_security_type : {
        1 : "A",
        2 : "B",
        3 : "C"
    },

    notice_status : {
        none : 0,
        read : 1
    },
    consulting_qna_title : {
        1 : "문제 Q&A",
        2 : "개념 Q&A",
        3 : "기타"
    },
    cancelled_point_status : 9, // 취소된 벌점의 status

    coupon_status : {
        0 : "설정",
        1 : "추가",
        2 : "차감"
    },
    
    seat_layout_common_gender : "none",

    employment_type:{
        1:"정규직",
        2:"비정규직",
    },
    main_work:{
        0:"센터 관리",
        1:"사무 업무",
        2:"학생 관리",
        3:"과목 상담",
        4:"멘토링 상담",
        5:"학습 동기 부여 상담"
    },
    //pay_method
    academic_backgrounds_status:{
        0:"졸업",
        1:"재학",
        2:"중퇴",
        3:"휴학",
    },
    computer_skill:{
        1:"상",
        2:"중",
        3:"하",
    },
    teacher_status:{
        0:"재직중",
        9:"퇴사",
    },
    injenium_reason:{
        0: "퇴실 대기",
        1: "학원 외출 대기",
        2: "화장실 이용",
        3: "졸음",
        4: "입실 대기",
    },
}